import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationGuardService {

	public redirectUrl: string;

	constructor(
		private authenticationService: AuthenticationService,
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

		if (this.authenticationService.getFirebaseUID()) {
			this.redirectUrl = '';
			return true;
		}

		//console.log('Not authenticated, redirecting and adding redirect url...', state.url);
		this.redirectUrl = state.url;
		return false;
	}
}
